<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-tabs>
          <b-tab title="Content">
            <b-card no-body>
              <b-card-header>
                <b-card-title title-tag="h5">Content</b-card-title>
              </b-card-header>
              <b-card-body>
                <b-row>
                  <b-col md=8>
                    <b-row>
                      <b-col lg="4">
                        <b-form-group label-for="catName">
                          <label>Category (ID)<span class="text-danger mr5">*</span></label>
                          <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" v-model="row.mpc_name_id" placeholder="e.g Roda 4" />
                          <VValidate 
                            name="Category (ID)" 
                            v-model="row.mpc_name_id" 
                            :rules="{...mrValidation.mpc_name_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="4">
                        <b-form-group label-for="catName">
                          <label>Category (EN)<span class="text-danger mr5">*</span></label>
                          <b-form-input @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" v-model="row.mpc_name_en" placeholder="e.g 4 Wheels" />
                          <VValidate 
                            name="Category (EN)" 
                            v-model="row.mpc_name_en" 
                            :rules="{...mrValidation.mpc_name_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="4">
                        <b-form-group label-for="catName">
                          <label>Category (JP)<span class="text-danger mr5">*</span></label>
                          <b-form-input v-model="row.mpc_name_jp" placeholder="e.g 4つの車輪" />
                          <VValidate 
                            name="Category (JP)" 
                            v-model="row.mpc_name_jp" 
                            :rules="mrValidation.mpc_name_jp" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md=12>
                        <b-row>
                          <b-col md=8>
                            <b-row>
                              <b-col lg="6">
                                <b-form-group label-for="catStatus">
                                  <label>Status <span class="text-danger mr5">*</span></label>
                                  <b-form-radio-group
                                    :options="Config.mr.StatusOptions"
                                    v-model="row[$parent.statusKey]"
                                  />
                                  <VValidate 
                                    name="Status" 
                                    v-model="row[$parent.statusKey]" 
                                    :rules="mrValidation[$parent.statusKey]" 
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col md=3>
                                <label id="backgroundcolor">Background Color <span class="text-danger mr5">*</span></label>
                                <verte v-model="row.mpc_background_color" :model="'rgb'" menuPosition="right" :enableAlpha="false"></verte>
                                <VValidate 
                                  name="Background Color" 
                                  v-model="row.mpc_background_color" 
                                  rules="required" 
                                />
                              </b-col>
                              <b-col md=3>
                                <label id="fontcolor">Font Color <span class="text-danger mr5">*</span></label>
                                <verte name="fontcolor" v-model="row.mpc_font_color" :model="'rgb'" menuPosition="right" :enableAlpha="false">
                                  <svg viewBox="0 0 24 24">
                                    <path d="M0 20h24v4H0z"/>
                                    <path style="fill: #000" d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"/>
                                  </svg>
                                </verte>
                                <VValidate 
                                  name="Font Color" 
                                  v-model="row.mpc_font_color" 
                                  rules="required" 
                                />
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>    
                  <b-col md=4>
                    <div class="file-uploader">
                      <label>Image <span class="text-danger mr5">*</span></label>
                      <Uploader v-model="row.mpc_image" type="product_category"/>
                      <VValidate 
                        name="Image" 
                        v-model="row.mpc_image" 
                        :rules="{required:1}" 
                      />
                    </div>
                  </b-col>      
                  
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab title="SEO Settings">
            <b-card no-body>
              <b-card-header>
                <b-card-title title-tag="h5">SEO Settings</b-card-title>
              </b-card-header>
              <b-card-body>
                <b-tabs>
                  <b-tab title="ID">
                    <b-card>
                      <b-row>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaTitle">
                            <template #label>
                              Meta Title ID<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.mpc_seo_title_id" />
                            <VValidate 
                              name="Meta Title ID" 
                              v-model="row.mpc_seo_title_id" 
                              :rules="{...mrValidation.mpc_seo_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaTagsID">
                            <template #label>
                              Meta Keywords ID
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-tags id="homeMetaTagsID" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mpc_seo_keywords_id" />
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaDesc">
                            <template #label>
                              Meta Description ID<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.mpc_seo_desc_id" />
                            <VValidate 
                              name="Meta Desc ID" 
                              v-model="row.mpc_seo_desc_id" 
                              :rules="{...mrValidation.mpc_seo_desc_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                  <b-tab title="EN">
                    <b-card>
                      <b-row>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaTitle">
                            <template #label>
                              Meta Title EN<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.mpc_seo_title_en" />
                            <VValidate 
                              name="Meta Title EN" 
                              v-model="row.mpc_seo_title_en" 
                              :rules="{...mrValidation.mpc_seo_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaTagsEN">
                            <template #label>
                              Meta Keywords EN
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-tags id="homeMetaTagsEN" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mpc_seo_keywords_en" />
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaDesc">
                            <template #label>
                              Meta Description EN<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.mpc_seo_desc_en" />
                            <VValidate 
                              name="Meta Desc EN" 
                              v-model="row.mpc_seo_desc_en" 
                              :rules="{...mrValidation.mpc_seo_desc_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                  <b-tab title="JP">
                    <b-card>
                      <b-row>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaTitle">
                            <template #label>
                              Meta Title JP<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="row.mpc_seo_title_jp" />
                            <VValidate 
                              name="Meta Title JP" 
                              v-model="row.mpc_seo_title_jp" 
                              :rules="mrValidation.mpc_seo_title_jp" 
                            />
                          </b-form-group>
                          <b-form-group label-for="homeMetaTagsJP">
                            <template #label>
                              Meta Keywords JP
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-tags id="homeMetaTagsJP" placeholder="Type and press enter ..." remove-on-delete tag-variant="success" tag-class="text-white" v-model="row.mpc_seo_keywords_jp" />
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group label-for="homeMetaDesc">
                            <template #label>
                              Meta Description JP<span class="text-danger">*</span>
                              <b-badge variant="info" pill class="help-badge"
                                v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                                <i class="fas fa-question"></i>
                              </b-badge>
                            </template>
                            <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="row.mpc_seo_desc_jp" />
                            <VValidate 
                              name="Meta Desc JP" 
                              v-model="row.mpc_seo_desc_jp" 
                              :rules="mrValidation.mpc_seo_desc_jp" 
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>
                </b-tabs>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
        
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Save Changes</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
}
</script>